@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap);
/*body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*/
/*@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap');*/
/*@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');*/

html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
/*  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
/*  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;*/
}


.app {
  /*width: 960px;*/
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  font-size: 100%;
}

.about {
  width:100%;
  font-family: 'Source Sans Pro', Arial, sans-serif;
}
/*
.reader {
  margin-left:10%;
  margin-right:10%;
}
*/
.player-wrapper {
  position: relative;
  padding-top: 60%;
  margin-left: 10%;
  margin-right:10%;
}

/*.reader-wide {
  margin-left:10%;
  margin-right:10%;
}
*/
.player-wrapper-wide {
  position: relative;
  padding-top: 30%;
  margin-left: 30%;
  margin-right:30%;
}


.collections {
  margin: 10px;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;

}

/*.seenextbuttons {*/
  /*margin: 0px;*/
  /*padding: 0px;*/
/*}*/

.yugtun {
  font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
  font-size: 22px;
  color: #333333;
}

.yugtatun {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 28px;
  line-height: 28px;
  margin: 30px;
  margin-bottom: 10px;
  font-weight: 600;
  font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
  color: #333333;
}

.yugtatunsub {
  display: flex;
  justify-content: center;
  margin: 20px;
  margin-top: 10px;
  font-weight:500;
  font-style: italic;
  font-size: 28px;
  line-height: 28px;
  font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
  color: #333333;
}


/*.reader-text {
  font-size:25px;
  line-height:45px;
}
*/
.categoryGrid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 25px;
  margin-left: 20px;
  margin-right: 20px;
}

.categoryButton {
  position: relative;
  text-align: center;
}

.categoryButton:hover{
  -webkit-filter: brightness(80%);
          filter: brightness(80%);
}


.categoryImage {
  width: 300px;
  border-radius: 10px;
  padding: 20px;

  /*position: relative;*/
  /*text-align: center;*/
}

.categoryText {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: white;
  color: black;
  padding: 20px;
  max-width: 60%;
  opacity: 90%;
  border-radius: 10px;
}


  .reader {
    margin-left:1%;
    margin-right:1%;
  }
  .reader-text {
    font-size:20px;
    line-height:35px;
  }


@media only screen and (min-width: 600px) {
  .categoryGrid {
      grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (min-width: 1025px) {
  .categoryGrid {
      grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 600px) {
  .reader {
    margin-left:1%;
    margin-right:1%;
  }
  .reader-text {
    font-size:20px;
    line-height:35px;
  }

  .player-wrapper {

    position: relative;
    padding-top: 60%;
    margin-left: 0%;
    margin-right:0%;
  }

}

nav ul {
  list-style: none;
  display: flex;
  background-color: black;
  margin-bottom: 20px;
}

nav ul li {
  padding: 20px;
}

nav ul li a {
  color: white;
  text-decoration: none;
}

.current {
  border-bottom: 4px solid white;
}

h1 {
  margin-bottom: 20px;
}

p {
  white-space:nowrap;
  /*margin-bottom: 15px;*/
}

